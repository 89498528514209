class Rules {
  req(requireds) {
    const rules = [];
    if (requireds.max) {
      const rule = (v) =>
        (v || "").length <= requireds.max ||
        `A maximum of ${requireds.max} characters is allowed`;

      rules.push(rule);
    }

    if (requireds.min) {
      const rule = (v) =>
        (v || "").length >= requireds.min ||
        `A minimum of ${requireds.min} characters is required`;

      rules.push(rule);
    }

    if (requireds.regex) {
      const rule = (v) =>
        requireds.regex[0].test(v || "") || "ID does not match.";

      rules.push(rule);
    }

    if (requireds.email) {
      const rule1 = (v) => !!v || "E-mail is required";
      const rule2 = (v) => /.+@.+\..+/.test(v) || "E-mail must be valid";

      rules.push(rule1);
      rules.push(rule2);
    }

    if (!requireds.allowSpaces) {
      const rule = (v) => (v || "").indexOf(" ") < 0 || "No spaces are allowed";

      rules.push(rule);
    }
    return rules;
  }
}

export default Rules;

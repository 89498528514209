<template>
  <div class="table_wrapper">
    <v-card class="p-3">
      <v-card-title
        >Admins
        <v-spacer></v-spacer>
        <v-btn color="primary" v-on:click="dialog3 = true">New</v-btn>
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Email
              </th>
              <th class="text-left">
                Created at
              </th>
              <th class="text-left">
                Updated at
              </th>
              <th class="text-left">
                Edit
              </th>
              <th class="text-left">
                Password
              </th>
              <th class="text-left">
                Delete
              </th>
            </tr>
          </thead>
          <tbody v-if="data">
            <tr v-for="(item, index) in data" :key="index">
              <td>
                {{ item.id }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.email }}
              </td>
              <td>
                {{ moment(item.created_at).fromNow() }}
              </td>
              <td>
                {{ moment(item.updated_at).fromNow() }}
              </td>
              <td>
                <v-btn
                  small
                  elevation="0"
                  dark
                  color="warning"
                  v-on:click="
                    ((dialog = true), (dialog2 = false)), (form = item)
                  "
                >
                  <v-icon small>mdi-pencil</v-icon>
                  Edit
                </v-btn>
              </td>
              <td>
                <v-btn
                  small
                  elevation="0"
                  dark
                  color="primary"
                  v-on:click="
                    ((dialog = false), (dialog2 = true)), (form = item)
                  "
                >
                  <v-icon small>mdi-pencil</v-icon>
                  Edit
                </v-btn>
              </td>
              <td>
                <v-btn
                  small
                  elevation="0"
                  dark
                  color="red"
                  v-on:click="deleteUser(item)"
                >
                  <v-icon small>mdi-close</v-icon>
                  Delete
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">User Information</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Name*"
                    :rules="rules"
                    v-model="form.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    :rules="rulesEmail"
                    v-model="form.email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="permissions"
                    v-model="selectedPer"
                    multiple
                    deletable-chips
                    chips
                    item-text="name"
                    item-value="id"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" :disabled="!valid" text @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">User Password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Password*"
                  v-model="form2.password"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Password Confirm*"
                  v-model="form2.passwordConfirm"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            :disabled="
              form2.password != '' && form2.password === form2.passwordConfirm
                ? false
                : true
            "
            text
            @click="save2"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog3" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">New User</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid3" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Name*"
                    :rules="rules"
                    v-model="form.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    :rules="rulesEmail"
                    v-model="form.email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password*"
                    :rules="rules"
                    v-model="form.password"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="permissions"
                    v-model="selectedPer"
                    multiple
                    deletable-chips
                    chips
                    item-text="name"
                    item-value="id"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog3 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" :disabled="!valid3" text @click="save3">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm ref="confirm"></confirm>

    <v-bottom-sheet v-model="loading" persistent>
      <v-sheet class="text-center" height="200px">
        <div class="pt-5">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <h5 class="mt-3">Loading, Please wait..</h5>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";
import Confirm from "../Components/Confirm.vue";
import RulesClass from "../../services/rules";
let rulesClass = new RulesClass();

export default {
  name: "Admins",
  components: {
    confirm: Confirm,
  },
  data() {
    return {
      valid: true,
      valid2: true,
      valid3: true,
      dialog: false,
      dialog2: false,
      dialog3: false,
      loading: true,
      moment: moment,
      data: [],
      permissions: [],
      token: localStorage.getItem("authToken"),
      page: 1,
      per_page: 100,
      selectedPerCurrent: [],
      form: {
        email: "",
        name: "",
        password: "",
        permissions: [],
      },
      form2: {
        password: "",
        passwordConfirm: "",
      },
      requireds: {
        allowSpaces: true,
        min: 2,
      },
    };
  },
  methods: {
    deleteUser(item) {
      this.$refs.confirm
        .open("Delete", "Are you sure?", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            axios
              .delete(
                process.env.VUE_APP_API_URL +
                  "admins/" +
                  item.id +
                  "?api_token=" +
                  this.token
              )
              .then((res) => {
                this.getList();
              })
              .catch((err) => console.log(err));
          }
        });
    },
    save3() {
      this.form.permissions = this.selectedPerCurrent;

      axios
        .post(
          process.env.VUE_APP_API_URL + "admins?api_token=" + this.token,
          this.form
        )
        .then((res) => {
          this.dialog3 = false;
          this.getList();
        })
        .catch((err) => console.log(err));
    },
    save2() {
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "admins/" +
            this.form.id +
            "/password?api_token=" +
            this.token,
          this.form2
        )
        .then((res) => {
          this.dialog2 = false;
          this.getList();
        })
        .catch((err) => console.log(err));
    },
    save() {
      this.form.permissions = this.selectedPerCurrent;

      axios
        .put(
          process.env.VUE_APP_API_URL +
            "admins/" +
            this.form.id +
            "?api_token=" +
            this.token,
          this.form
        )
        .then((res) => {
          this.dialog = false;
          this.getList();
        })
        .catch((err) => console.log(err));
    },
    getList() {
      this.loading = true;
      const url =
        process.env.VUE_APP_API_URL +
        "admins/filter?page=" +
        this.page +
        "&per_page=" +
        this.per_page +
        "&api_token=" +
        this.token;
      axios
        .post(url)
        .then((res) => {
          this.data = res.data.data.data;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_API_URL +
          "admins/permissions?api_token=" +
          this.token
      )
      .then((res) => {
        this.permissions = res.data.data;
      })
      .catch((err) => console.log(err));

    this.getList();
  },
  computed: {
    selectedPer: {
      get: function() {
        return this.form.permissions.map((a) => {
          return a.name;
        });
      },
      set: function(value) {
        this.selectedPerCurrent = value;
      },
    },
    ...mapGetters("auth", ["user"]),
    rules() {
      return rulesClass.req(this.requireds);
    },
    rulesEmail() {
      return rulesClass.req({ email: true });
    },
  },
};
</script>
